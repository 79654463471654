const initBlogSlider = async function( $block ) {
	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );
	const { Navigation, Pagination } = await import( "swiper/modules" );
	const $slider = $block.find( ".blog__slider" );
	if ( !$slider.length ) { return false; }
	
	let galleryImages = [];
	$( $slider ).each( function( index ) {
		let galleryImagesItem = $( this );
		galleryImagesItem.addClass( "blogSlide-" + index );

		function initSwiper() {
			if ( window.innerWidth > 1199 ) {
				galleryImages[ index ] = new Swiper( ".blogSlide-" + index, {
					modules: [ Navigation, Pagination ],
					slidesPerView: 1,
					speed: 1000,
					spaceBetween: 20,
					pagination: {
						el: ".blog__slider--pagination-" + index,
						type: "bullets",
						clickable: true,
					},
					navigation: {
						nextEl: ".blog__slider--next-" + index,
						prevEl: ".blog__slider--prev-" + index,
					},
				} );
			}
		}

		initSwiper();

		$( window ).on( "resize", function() {
			if ( window.innerWidth > 1199 ) {
				if ( !galleryImages[ index ] ) {
					initSwiper();
				}
			} else {
				if ( galleryImages[ index ] ) {
					galleryImages[ index ].destroy();
					galleryImages[ index ] = undefined;
				}
			}
		} );
	} );

};
 
themeUtils.loadBlock( initBlogSlider, "blog", ".section-blog" );

$( window ).on( "load", function() {
	// tabs
	$( ".blog__tabs" ).on( "click", "li:not( .active )", function() { 
		$( this )
			.addClass( "active" ).siblings().removeClass( "active" )
			.closest( ".section-blog" ).find( ".blog__tab" ).removeClass( "active" ).eq( $( this ).index() ).addClass( "active" );
	} );

	function hideBlogSlide() {
		$( ".blog__tab" ).each( function() {
			var $tab = $( this );
	
			if ( window.innerWidth < 1199 ) {
				$tab.find( ".swiper-slide" ).not( ":first" ).hide();
	
				if ( $tab.find( ".swiper-slide" ).length <= 1 ) {
					$tab.find( ".blog__load-more" ).hide();
				} else {
					$tab.find( ".blog__load-more" ).show();
				}

				$tab.off( "click", ".blog__load-more" ).on( "click", ".blog__load-more", function() {
					var currentSlide = $tab.find( ".swiper-slide:visible" );
					var nextSlide = currentSlide.next( ".swiper-slide" );
					nextSlide.show();
	
					if ( $tab.find( ".swiper-slide:hidden" ).length <= 1 ) {
						$tab.find( ".blog__load-more" ).hide();
					}
				} );
			} else {
				$tab.find( ".swiper-slide" ).show();
				$tab.find( ".blog__load-more" ).hide();
			}
		} );
	}
	
	hideBlogSlide();
	
	$ ( window ).on( "resize", function() {
		hideBlogSlide();
	} );
} );
